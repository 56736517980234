import firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
import * as environmentUtil from 'util/environment';
import _ from 'lodash';



export type IUserStatus = firebase.User | null | 'NO_LOGIN_ATTEMPTED_YET';

const __subject = new BehaviorSubject<IUserStatus>('NO_LOGIN_ATTEMPTED_YET');

let __firebaseApp: firebase.app.App;

let __pauseAuthStateChangeEvents = false;



export function init(config: environmentUtil.IEnvironment) {
  __firebaseApp = firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain
  });

  __firebaseApp.auth().onAuthStateChanged(async user => {
    if (!__pauseAuthStateChangeEvents) {
      __subject.next(user);
    }

    if (config.environmentName === 'devLocal' && user) {
      console.log(await user.getIdToken());
    }
  });
}



export function signIn(email: string, password: string) {
  firebase.auth().signInWithEmailAndPassword(email, password);
}



export function sendPasswordResetEmail(email: string) {
  return firebase.auth().sendPasswordResetEmail(email);
}



export function logOut() {
  return firebase.auth().signOut();
}



export function authStatusAsObservable() {
  return __subject.asObservable();
}



export function getApp() {
  return __firebaseApp;
}



export function getLoggedInUser() {
  return __subject.getValue();
}



export async function updateCredentials(email: string, password: string) {
  const user = __subject.getValue();
  if (!user || user === 'NO_LOGIN_ATTEMPTED_YET') {
    return;
  }

  if (email) {
    await user.updateEmail(email);
  }

  if (password) {
    await user.updatePassword(password);
  }
}



export async function signUp(email: string, password: string, inviteCode: string, fullName: string, encodedFileUrl?: string): Promise<boolean> {
  __pauseAuthStateChangeEvents = true;
  try {
    const result = await __firebaseApp.auth().createUserWithEmailAndPassword(email, password);
    const idToken = result !== null && result.user !== null ? await result.user.getIdToken() : '';

    const config = environmentUtil.getConfig();
    await fetch(config.api.url + config.api.pathToGraphQlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `
          mutation RegisterSiteUser($userInput: RegisterSiteUserInput!) {
            registerSiteUser(userInput: $userInput) {
              id
            }
          }
        `,
        variables: {
          userInput: {
            idToken: idToken.toString(),
            inviteCode,
            fullName,
            profilePictureUrl: encodedFileUrl
          }
        }
      })
    })
      .then(response => response.json())

      .then(jsonResponse => {
        if (!_.isEmpty(jsonResponse.errors)) {
          throw new Error('REQUEST_FAILED');
        }
      });

    __pauseAuthStateChangeEvents = false;
    __subject.next(result.user);

    return true;
  } catch (error) {
    console.error(error);
    __pauseAuthStateChangeEvents = false;

    return false;
  }
}
