import * as React from 'react';
import _ from 'lodash';
import * as firebaseUtil from 'util/firebase';
import * as dataService from './dataService';
import {
  Redirect,
  RouteComponentProps
} from 'react-router';
import { routes } from 'routes';
import { AppToolbar } from '../../toolbars/AppToolbar/AppToolbar';
import { ProjectPickerToolbar } from 'components/toolbars/ProjectPickerToolbar/ProjectPickerToolbar';
import { NotificationMenu } from 'components/shared/NotificationDrawer/NotificationDrawer';

import styles from './PageTemplate.module.css';



export interface IPageTemplateProps extends RouteComponentProps {
  requiresUser?: boolean;
  requiresAdmin?: boolean;
  toolbarType?: 'pickerToolbar' | 'projectToolbar' | 'hidden';
}

export const PageTemplate: React.FC<IPageTemplateProps> = props => {
  const queryTuple = dataService.usePageTemplateQuery();
  const [fnSaveNotification] = dataService.useSaveNotificationMutation();
  const [fnSaveAllNotification] = dataService.useSaveAllNotificationsMutation();
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = React.useState(false);



  const notifications = queryTuple.data ? queryTuple.data.notifications : [];
  const hasUnreadNotifications = _.some(notifications, notification => {
    return !notification.isRead;
  });

  if (props.requiresUser && !firebaseUtil.getLoggedInUser()) {
    return <Redirect to={routes.auth.path('login')} />;
  }

  if (props.requiresAdmin && queryTuple.data && !queryTuple.data.isSuperAdmin) {
    return <Redirect to={routes.home.path()} />;
  }



  function handleCardClick(id: string) {
    setIsNotificationMenuOpen(false);
    fnSaveNotification({
      isRead: true,
      notificationId: id
    });
  }



  function handleMarkAllAsRead() {
    setIsNotificationMenuOpen(false);
    fnSaveAllNotification({
      isRead: true
    });
  }



  return (
    <div className={styles.root}>
      {props.toolbarType === 'projectToolbar' && (
        <div className={styles.appToolbarContainer}>
          <AppToolbar
            hasUnreadNotifications={hasUnreadNotifications}
            onClickNotification={() => setIsNotificationMenuOpen(!isNotificationMenuOpen)}
            {...props} />
        </div>
      )}

      {props.toolbarType === 'pickerToolbar' && (
        <div className={styles.appToolbarContainer}>
          <ProjectPickerToolbar
            {...props} />
        </div>
      )}

      {isNotificationMenuOpen && (
        <NotificationMenu
          notifications={notifications}
          onClose={() => setIsNotificationMenuOpen(false)}
          onClickNotification={handleCardClick}
          onMarkAllAsRead={handleMarkAllAsRead} />
      )}

      {!(props.requiresAdmin && queryTuple.loading) &&
        <div className={styles.content}>
          {props.children}
        </div>
      }
    </div>
  );
};
