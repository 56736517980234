import * as React from 'react';
import _ from 'lodash';
import {
  ITab,
  TabBar
} from 'components/shared/TabBar/TabBar';
import {
  IProjectMilestoneData,
  ISaveActionArgs,
} from '../dataService';
import { YourActionsTab } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/YourActionsTab/YourActionsTab';
import { AttachmentsTab } from 'components/pages/MilestonePage/ProjectMilestone/ProjectTabs/AttachmentsTab/AttachmentsTab';
import { Button } from 'components/shared/Button/Button';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { LogTab } from './ProjectTabs/LogTab/LogTab';

import styles from './ProjectMilestone.module.css';

import imgReorder from 'assets/images/reorder.svg';
import imgCompleted from 'assets/images/bigBlueCheckmark.svg';
import imgChevron from 'assets/images/grayChevron.svg';
import imgRedX from 'assets/images/redX.svg';
import imgPencil from 'assets/images/pencil.svg';



export interface IProjectMilestoneProps {
  milestone: IProjectMilestoneData;
  draggableProps?: DraggableProvidedDragHandleProps;
  currentProfilePic: string | null;
  userAuthorizedAsAdmin: boolean;

  onAddAction: () => void;
  onClickAddAttachment: () => void;
  onAddNote: (note: string) => void;
  onSaveAction: (input: ISaveActionArgs) => void;
  onDeleteAction: (actionId: string) => void;
  onSaveMilestone: () => void;
  onReleaseMilestone: () => void;
  onSubmitMilestone: () => void;
  onDeleteMilestone: () => void;
}

export const ProjectMilestone: React.FC<IProjectMilestoneProps> = props => {
  const [isMinimized, setIsMinimized] = React.useState(props.milestone.status.name === 'accepted');

  return (
    <>
      {isMinimized && (
        <CompletedProjectMilestone
          position={props.milestone.rank}
          title={props.milestone.title}
          onClick={() => setIsMinimized(false)} />
      )}

      {!isMinimized && (
        <PendingProjectMilestone
          {...props} />
      )}
    </>
  );
};



const PendingProjectMilestone: React.FC<IProjectMilestoneProps> = props => {
  const tabDefs: ITab[] = [
    {
      'id': 'attachments',
      'displayName': "ATTACHMENTS"
    },
    {
      'id': 'logs',
      'displayName': "LOGS + NOTES"
    }
  ];

  if (!_.isEmpty(props.milestone.actions) || props.userAuthorizedAsAdmin) {
    tabDefs.unshift({
      'id': 'yourActions',
      'displayName': "YOUR ACTIONS"
    });
  }

  const [selectedId, setSelectedId] = React.useState(tabDefs[0].id);
  const userIsAdmin = props.userAuthorizedAsAdmin;

  return (
    <div className={styles.root}>
      {userIsAdmin && (
        <div className={styles.cornerDiv}>
          {props.milestone.status.name !== 'accepted' && (
            <>
              <Button
                withoutStyle
                onClick={props.onSaveMilestone}
                extraClassName={styles.imageOptionContainerPencil}>
                <img
                  src={imgPencil}
                  alt="edit" />
              </Button>

              <Button
                withoutStyle
                onClick={props.onDeleteMilestone}
                extraClassName={styles.imageOptionContainer}>
                <img
                  src={imgRedX}
                  alt="delete" />
              </Button>
            </>
          )}

          {props.milestone.status.name === 'inProgress' && (
            <Button
              withoutStyle
              onClick={props.onReleaseMilestone}
              extraClassName={styles.releaseContainer}>
              <div className={styles.releaseText}>
                Release Milestone
              </div>
            </Button>
          )}

          {props.milestone.status.name === 'pending' && (
            <div
              {...props.draggableProps}
              className={styles.dragContainer}>
              <img
                className={styles.dragImg}
                src={imgReorder}
                alt="reorder" />

              <div className={styles.dragText}>
                Drag to Reorder
              </div>
            </div>
          )}
        </div>
      )}

      {!userIsAdmin && (
        <div className={styles.cornerDiv}>
          {props.milestone.status.name === 'submitted' && (
            <Button
              onClick={props.onSubmitMilestone}
              extraClassName={styles.acceptButton}>
              Accept Milestone
            </Button>
          )}

          {props.milestone.status.name === 'pending' && (
            <div className={styles.disabledAcceptButton}>
              Must Accept Previous Milestone First
            </div>
          )}

          {props.milestone.status.name === 'inProgress' && (
            <div className={styles.disabledAcceptButton}>
              Milestone In Progress
            </div>
          )}
        </div>
      )}

      <div className={styles.textBox}>
        <div className={styles.milestone}>
          MILESTONE {props.milestone.rank + 1}
        </div>

        <h1 className={styles.milestoneTitle}>
          {props.milestone.title}
        </h1>

        <div className={styles.milestoneDesc}>
          {props.milestone.description}
        </div>
      </div>

      <div className={styles.infoBox}>
        <TabBar
          tabDefs={tabDefs}
          selectedTabId={selectedId}
          onSelect={setSelectedId} />

        {selectedId === 'yourActions' && (
          <YourActionsTab
            milestoneStatus={props.milestone.status}
            userAuthorizedAsAdmin={userIsAdmin}
            actions={props.milestone.actions}
            onClick={props.onAddAction}
            onDeleteAction={props.onDeleteAction}
            onSaveAction={props.onSaveAction} />
        )}

        {selectedId === 'attachments' && (
          <AttachmentsTab
            attachments={props.milestone.attachments}
            onClickAddAttachment={props.onClickAddAttachment} />
        )}

        {selectedId === 'logs' && (
          <LogTab
            isAccepted={props.milestone.status.name === 'accepted'}
            onSendNote={props.onAddNote}
            currUserProfilePicUrl={props.milestone.loggedInUserPic}
            logs={props.milestone.logs} />
        )}
      </div>
    </div>
  );
};



interface ICompletedProjectMilestoneProps {
  position: number;
  title: string;

  onClick: () => void;
}

const CompletedProjectMilestone: React.FC<ICompletedProjectMilestoneProps> = props => {
  return (
    <div className={styles.completedMilestone}>
      <div className={styles.textBox}>
        <div className={styles.milestone}>
          MILESTONE {props.position + 1}
        </div>

        <h1 className={styles.milestoneTitle}>
          {props.title}
        </h1>
      </div>

      <Button
        onClick={props.onClick}
        extraClassName={styles.completedContent}
        withoutStyle>
        <div className={styles.completeText}>
          Click to Expand and View Historical Content
        </div>

        <img
          className={styles.chevron}
          src={imgChevron}
          alt="Chevron" />
      </Button>

      <div className={styles.completedImgContainer}>
        <img
          className={styles.completedImg}
          src={imgCompleted}
          alt="Completed Milestone" />
      </div>
    </div>
  );
};
