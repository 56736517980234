import * as React from 'react';
import _ from 'lodash';
import { Button } from '../Button/Button';
import { INotificationData } from 'components/pages/PageTemplate/dataService';
import { NotificationCard } from './NotificationCard/NotificationCard';
import { useOnClickOutsideRef } from 'util/hooks';

import styles from './NotificationDrawer.module.css';

import imgX from 'assets/images/x.svg';



export interface INotificationMenuProps {
  notifications: INotificationData[];

  onClose: () => void;
  onClickNotification: (id: string) => void;
  onMarkAllAsRead: () => void;
}

export const NotificationMenu: React.FC<INotificationMenuProps> = props => {
  const refContainer = useOnClickOutsideRef<HTMLDivElement>(props.onClose, props);

  return (
    <div
      ref={refContainer}
      className={styles.root}>
      <div className={styles.topContent}>
        <Button
          onClick={props.onClose}
          withoutStyle>
          <img
            className={styles.exitButton}
            src={imgX} />
        </Button>

        <div className={styles.textAndButtonContainer}>
          <h1 className={styles.title}>
            Notifications
          </h1>

          <Button
            onClick={props.onMarkAllAsRead}
            withoutStyle>
              <div className={styles.markAllAsReadButton}>
                Mark All as Read
              </div>
          </Button>
        </div>
      </div>

      <div className={styles.notificationColumn}>
        {_.map(props.notifications, notification => {
          return (
            <div className={styles.notificationContainer}>
              <NotificationCard
                id={notification.id}
                isRead={notification.isRead}
                description={notification.description}
                title={notification.title}
                projectStatus={notification.projectDescription}
                projectLogoUrl={notification.projectLogoUrl}
                onClick={props.onClickNotification} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
